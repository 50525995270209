<template lang="pug">
	skelet
		navHeader(slot='header', interface_name='root')
		ul#sidebar-scroll.menu.menu-nav(slot='sidebar', v-show='LoggedIn')
			li.menu-item(
				:class='{ active: $route.name === "root-usersmanagment" }',
				v-if='$checkPermission("userManager", $store)',
				@click='$router.push("/root/usersmanagment")',
				@click.middle.prevent='$openInNewTab("root/usersmanagment")',
				@contextmenu.prevent='$openInNewTab("root/usersmanagment")'
			)
				a
					v-icon(dark, small, left) people
					|
					| Пользователи
			li.menu-item(
				:class='{ active: $route.name === "root-agencies" }',
				v-if='$checkPermission("admin", $store)',
				@click='$router.push("/root")',
				@click.middle.prevent='$openInNewTab("root")',
				@contextmenu.prevent='$openInNewTab("root")'
			)
				a
					v-icon(dark, small, left) corporate_fare
					| Агентства
			li.menu-item(
				:class='{ active: $route.name === "root-table" }',
				v-if='$checkPermission("admin", $store)',
				@click='$router.push("/root/table")',
				@click.middle.prevent='$openInNewTab("root/table")',
				@contextmenu.prevent='$openInNewTab("root/table")'
			)
				a
					v-icon(dark, small, left) insert_chart_outlined
					| Сводная таблица
			li.menu-item(
				:class='{ active: $route.name === "root-report" }',
				v-if='$checkPermission("admin", $store)',
				@click='$router.push("/root/report")',
				@click.middle.prevent='$openInNewTab("root/report")',
				@contextmenu.prevent='$openInNewTab("root/report")'
			)
				a
					v-icon(dark, small, left) mdi-signal-cellular-3
					| Отчёты
			li.menu-item.space.mobile-userInfo(
				v-show='isNewUser != undefined',
				@click='$router.push("/")'
				@click.middle.prevent='$openInNewTab("login")',
				@contextmenu.prevent='$openInNewTab("login")'
			)
				a
					v-icon(dark, small, left) navigate_before
					| Выйти на главный экран
			li.menu-item.space.mobile-userInfo(
				@click='Logout()',
				@click.middle.prevent='$openInNewTab("")',
				@contextmenu.prevent='$openInNewTab("")'
			)
				a
					v-icon(dark, small, left) navigate_before
					| Выйти
		div.padding_content(slot='content')
			div(v-if='LoggedIn === true')
				router-view
				SelfInfo(:dialogFormVisible.sync='dialogFormVisible')
			.content.d-flex(
				v-show='!LoggedIn',
				:class='{ loading: !LoggedIn, "loading-lg": !LoggedIn }',
				style='width: 100%'
			)
</template>

<script>
import SelfInfo from '@/components/SelfInfo';
import navHeader from '@/components/nav/header';
import skelet from '@/components/nav/skelet';
import {mapActions, mapMutations} from 'vuex'
import {CHECK_SESSION, LOGOUT} from '@/store/const/auth'
import {ACCOUNTS, AGENCY_DATA, PROFILE, SUMMARY_INFO} from '@/store/const/root'
import loading from '@/mixins/loading_mixin';

export default {
	components: {
		SelfInfo,
		navHeader,
		skelet,
	},
	mixins: [loading],
	data: function () {
		return {
			CheckSessionTimer: 0,
			LoginData: {
				login: '',
				password: '',
			},
			LoggedIn: false,
			Loading: true,
			Error: '',
			SessionInvalid: false,
			CampaignTypes: [],
			isNewUser: undefined,
			dialogFormVisible: false,
		};
	},
	async mounted() {
		this.setLoadingActions()
		this.isNewUser = sessionStorage.getItem('session');
		await this.CHECK_SESSION()
		if (!this.isError(CHECK_SESSION)) {
			this.LoggedIn = true;
			this.profile();
			this.InitData();
		} else {
			this.logout();
		}
	},
	methods: {
		...mapMutations('Auth', [LOGOUT]),
		...mapActions('Auth', [CHECK_SESSION]),
		...mapActions('Root', {
			profile: PROFILE,
			agency_data: AGENCY_DATA,
			summary_info: SUMMARY_INFO,
			accounts: ACCOUNTS,
		}),
		setLoadingActions() {
			this.actions = [
				CHECK_SESSION,
				AGENCY_DATA,
				SUMMARY_INFO,
				ACCOUNTS
			];
		},
		Logout() {
			this.LoggedIn = false;
			this.Loading = false;
			this.Error = '';
			this.LOGOUT()
			this.$router.push({path: '/login'});
		},
		InitData() {
			let startDate = this.$moment().startOf('month').valueOf();
			let endDate = this.$moment().endOf('month').set({hour: 23, minute: 59, second: 59}).valueOf();
			this.agency_data();
			this.summary_info({
				date: {
					start: startDate,
					stop: endDate,
				},
			});
			this.accounts({})
		},
	},
};
</script>

<style lang="scss">
.container {
	max-width: inherit;
}

#sidebar-id {
	background: #334;
	color: #ddd;
}

a {
	cursor: pointer;
}

.space {
	margin-bottom: 0.4rem;
}

.off-canvas-content {
	min-width: auto !important;
}
</style>
